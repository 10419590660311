import _ from "lodash";
import { NavItemType } from "@/shared/Navigation/NavigationItem";

const ncNanoId = _.uniqueId;

export const LOGO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "JUST-FREE-GAMES.COM",
    className: "font-semibold",
  },
];

export const LOGO_MOBILE: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "JUST-FREE-GAMES.COM",
    className:
      "font-semibold py-0 px-0 hover:bg-transparent dark:hover:bg-transparent",
  },
];

// TODO: remember to update .htaccess file when changing the navigation items
export const NAVIGATION: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/about",
    name: "What is JFG?",
  },
];
