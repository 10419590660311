export interface Fixture {
  model: string;
  pk: number;
}

export interface PlatformFixture extends Fixture {
  fields: {
    title: string;
  };
}

export interface GiveawayFixture extends Fixture {
  fields: {
    type:
      | "GAME"
      | "DEMO"
      | "DLC"
      | "LOOT"
      | "ALPHA"
      | "BETA"
      | "MEMBERSHIP"
      | "OTHER";
    title: string;
    description: string;
    url: string;
    supplier: string;
    msrp: number | null;
    status: "CREATED" | "QUEUED" | "PUBLISHED" | "CANCELED" | "EXPIRED";
    created_at: string;
    updated_at: string;
    expiration_date: string | null;
    publish_to_socials: boolean;
    show_source: boolean;
    post_id: string;
    post_title: string;
    post_url: string;
    post_image: string;
    steam_grid_db_image: string;
    platforms: string[];
  };
}

export interface SelectProperty {
  id: string | number;
  name: string;
  description: string;
  checked: boolean;
}

export const JFG_COOKIE_CONSENT = "jfgCookiesConsent";

export const JFG_URL = "https://www.just-free-games.com";

export const JFG_TITLE_TEXT = "JFG - Just Free Games";

export const JFG_INTRO_TEXT = `Just Free Games is a platform for discovering and collecting giveaways and freebies for your favorite games. We are not affiliated with any game or company. We are just passionate gamers who love to collect and share freebies.`;

export const JFG_DESCRIPTION_TEXT =
  "Discover and claim free games, DLCs, and loot for Steam, GOG, Epic Games, Ubisoft, EA, Xbox, PlayStation and others";
