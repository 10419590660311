import React from "react";
import NavigationItem, { NavItemType } from "./NavigationItem";

const Navigation = ({ items = [] }: { items: NavItemType[] }) => {
  return (
    <ul className="nc-Navigation flex h-full">
      {items.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
};

export default Navigation;
