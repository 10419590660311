import { SocialType, SOCIALS_DATA } from "@/shared/SocialsShare/SocialsShare";
import React, { FC } from "react";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const socialsData: SocialType[] = SOCIALS_DATA;

export const SOCIALS = socialsData;

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block",
  socials = socialsData,
}) => {
  return (
    <nav
      className={`nc-SocialsList flex text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
    >
      {socials.map((item, i) => (
        <a
          key={i}
          className={`self-center text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center ${itemClass}`}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <div dangerouslySetInnerHTML={{ __html: item.icon || "" }}></div>
        </a>
      ))}
    </nav>
  );
};

export default SocialsList;
