import(/* webpackMode: "eager" */ "/Users/danielecarrucciu/projects/just-free-games-frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/Users/danielecarrucciu/projects/just-free-games-frontend/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/Users/danielecarrucciu/projects/just-free-games-frontend/node_modules/rc-slider/assets/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/danielecarrucciu/projects/just-free-games-frontend/src/components/GoogleAnalytics.tsx");
;
import(/* webpackMode: "eager" */ "/Users/danielecarrucciu/projects/just-free-games-frontend/src/images/hero_original_open_graph_meta_tag.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/danielecarrucciu/projects/just-free-games-frontend/src/shared/CookieConsent/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/danielecarrucciu/projects/just-free-games-frontend/src/shared/MenuBar/MenuBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/danielecarrucciu/projects/just-free-games-frontend/src/shared/Navigation/NavigationItem.tsx");
;
import(/* webpackMode: "eager" */ "/Users/danielecarrucciu/projects/just-free-games-frontend/src/styles/index.scss");
