"use client";
import { JFG_COOKIE_CONSENT } from "@/data/giveaway_types";
import Link from "next/link";
import { useEffect, useState } from "react";
import CookieConsent, {
  OPTIONS,
  getCookieConsentValue,
} from "react-cookie-consent";
import { UrlObject } from "url";

// call this function to check if the cookie has changed on interval
export const checkCookie = (
  onCookieChange: (value: string | undefined) => void
) => {
  let lastCookie = getCookieConsentValue(JFG_COOKIE_CONSENT);
  let currentCookie = getCookieConsentValue(JFG_COOKIE_CONSENT);
  if (currentCookie !== lastCookie) {
    lastCookie = currentCookie;
  }

  onCookieChange(currentCookie);
};

const JfgCookieConsent: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const cookiePolicyUrl: UrlObject = {
    pathname: "about",
    hash: "#section-cookie-policy",
  };

  const onCookieChange = (value: string | undefined) => {
    if (value === undefined) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkCookie(onCookieChange);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <CookieConsent
      cookieName={JFG_COOKIE_CONSENT}
      expires={180}
      visible={visible ? "show" : "hidden"}
      disableStyles={true}
      location={OPTIONS.BOTTOM}
      containerClasses="lg:flex flex-wrap fixed bottom-0 left-0 w-full text-center lg:justify-between items-baseline z-50 bg-white dark:bg-neutral-900 dark:border-t dark:border-neutral-700 p-5"
      buttonClasses="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6 mt-3 lg:mt-0 ml-2 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0"
      buttonText="Accept Cookies"
      onAccept={() => {
        window.gtag("consent", "update", {
          ad_storage: "granted",
          ad_user_data: "granted",
          ad_personalization: "granted",
          analytics_storage: "granted",
        });
      }}
      enableDeclineButton
      declineButtonClasses="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6 mt-3 lg:mt-0 lg:ml-2 ttnc-ButtonSecondary border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0"
      declineButtonText="Decline"
    >
      {/* TODO: and serve tailored ads */}
      This website uses{" "}
      <Link
        className="underline text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
        href={cookiePolicyUrl}
      >
        cookies
      </Link>{" "}
      to analyze traffic.
    </CookieConsent>
  );
};

export default JfgCookieConsent;
