"use client";
import { JFG_COOKIE_CONSENT } from "@/data/giveaway_types";
import Script from "next/script";
import { getCookieConsentValue } from "react-cookie-consent";

const GoogleAnalytics = ({
  GA_MEASUREMENT_ID,
}: {
  GA_MEASUREMENT_ID: string;
}) => {
  const cookieValue = getCookieConsentValue(JFG_COOKIE_CONSENT);
  const gtagConsent = cookieValue === "true" ? "granted" : "denied";

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('consent', 'default', {
                  'ad_storage': '${gtagConsent}',
                  'ad_user_data': '${gtagConsent}',
                  'ad_personalization': '${gtagConsent}',
                  'analytics_storage': '${gtagConsent}'                
                });

                gtag('config', '${GA_MEASUREMENT_ID}', {
                    page_path: window.location.pathname,
                });
                `,
        }}
      />
    </>
  );
};

export default GoogleAnalytics;
